// Components used in documents
import { TrackedLink } from "@components/analytics/TrackedLink";
import NewToBlues from "@components/blog/NewToBlues";
import { DocImage } from "@components/doc/DocImage";
import Arguments from "@components/doc/Arguments";
import { VideoBox } from "@components/doc/VideoBox";
import { IntegratedCodeBlock } from "@components/integrated/IntegratedCodeBlock";
import CodeTabs from "@components/doc/CodeTabs";
import ExampleRequests from "@components/doc/ExampleRequests";
import ExampleResponse from "@components/doc/ExampleResponse";
import MoreInformation from "@components/doc/MoreInformation";
import { TabContent, Tabs } from "@components/doc/Tabs";
import {
  Title1,
  Title2,
  Title3,
  Title4,
  Title5,
  Title6,
  Subtitle,
} from "@components/doc/Title";
import { If } from "@components/doc/If";
import { Note, Success, Warning, Deprecated } from "@components/doc/Callout";
import ResponseMembers from "@components/doc/ResponseMembers";
import SlugPicker from "@components/doc/SlugPicker";
import {
  Board,
  Category,
  Notecard,
  Notecarrier,
  Language,
  Service,
  SwanDatasheetVersion,
  CygnetDatasheetVersion,
  OptaDatasheetVersion,
  AirnoteDatasheetVersion,
  QuickStartPath,
} from "@components/doc/HumanOption";
import Badge from "@components/doc/Badge";
import BadgePermission from "@components/doc/BadgePermission";
import { Previous, Next } from "@components/doc/PaginationLink";
import {
  IfUsingPseudoSensor,
  IfNotUsingPseudoSensor,
  IfSupportsPlatformIO,
  IfDoesNotSupportPlatformIO,
} from "@components/doc/SensorTutorialHelpers";
import {
  IfCellularV1SKU,
  IfCellularV2SKU,
  IfCellularV3SKU,
  IfCellularV4SKU,
  IfCellularXPSKU,
  IfV1SKU,
  IfNBGLFamilySKU,
  IfNBNAFamilySKU,
  IfNarrowBandSKU,
  IfSkuHasWifi,
  IfWBEXFamilySKU,
  IfWBNAFamilySKU,
  IfWideBandSKU,
  IfCellularNASKU,
  IfCellularGLSKU,
  IfWiFiOnlySKU,
  IfLoRaSKU,
  IfNotLoRaSKU,
  IfCellularSKU,
  IfMBGLFamilySKU,
  IfMBNAFamilySKU,
  IfMidBandSKU,
  NotecardSKUCheck,
} from "@components/doc/NotecardSKUHelpers";
import CommunityProjects from "@components/community/CommunityProjects";
import DocTable from "@components/doc/DocTable";
import QuickstartStep from "@components/utility/QuickstartStep";
import VideoCallout from "@components/utility/VideoCallout";
import { SignInToNotehub } from "@components/SignInToNotehub";
import KiCanvas from "@components/doc/KiCanvas";
import RequestInformation from "@components/doc/RequestInformation";
import VersionCheck from "@components/doc/VersionCheck";

// Provide these to MDX files rather than importing from each file
export const MDXComponentMap = {
  // Markdown magic we want to override.
  a: TrackedLink, // [like](http://this)    [like](#this)    <a href="this">like</a>   bare links https://this.com
  pre: IntegratedCodeBlock, // CodeBlocks fenced with triple back ticks:  ```
  h1: Title1, // Lines starting with `# `
  h2: Title2, // Lines starting with `## `
  h3: Title3, // Lines starting with `### `
  h4: Title4, // Lines starting with `#### `
  h5: Title5, // Lines starting with `##### `
  h6: Title6, // Lines starting with `###### `
  img: DocImage,
  table: DocTable,

  // Tags we've added for tech writers to use in their mdx work
  Arguments,
  Badge,
  BadgePermission,
  CodeBlock: IntegratedCodeBlock,
  CodeTabs,
  DocTable,
  ExampleRequests,
  ExampleResponse,
  If,
  MoreInformation,
  Note,

  IfUsingPseudoSensor,
  IfNotUsingPseudoSensor,
  IfSupportsPlatformIO,
  IfDoesNotSupportPlatformIO,

  IfCellularV1SKU,
  IfCellularV2SKU,
  IfCellularV3SKU,
  IfCellularV4SKU,
  IfCellularXPSKU,
  IfV1SKU,
  IfNBGLFamilySKU,
  IfNBNAFamilySKU,
  IfNarrowBandSKU,
  IfSkuHasWifi,
  IfWBEXFamilySKU,
  IfWBNAFamilySKU,
  IfWideBandSKU,
  IfCellularNASKU,
  IfCellularGLSKU,
  IfWiFiOnlySKU,
  IfLoRaSKU,
  IfNotLoRaSKU,
  IfCellularSKU,
  IfMBGLFamilySKU,
  IfMBNAFamilySKU,
  IfMidBandSKU,
  NotecardSKUCheck,

  RequestInformation,
  ResponseMembers,
  SignInToNotehub,
  Subtitle,
  Success,
  TabContent,
  Tabs,
  VideoBox,
  Warning,
  Deprecated,

  // Simple variable interpolation. e.g. to turn a variable 'c-cpp' into 'C/C++'
  Board,
  Category,
  Notecard,
  Notecarrier,
  Language,
  Service,
  SwanDatasheetVersion,
  CygnetDatasheetVersion,
  OptaDatasheetVersion,
  AirnoteDatasheetVersion,
  QuickStartPath,

  // Dropdowns to set prop values in url slugs
  SlugPicker,

  // Pagination Buttons
  Previous,
  Next,

  NewToBlues,
  QuickstartStep,
  VideoCallout,

  CommunityProjects,
  KiCanvas,

  VersionCheck,
};
